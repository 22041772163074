
import { IonButtons, IonContent, IonMenuButton, IonPage } from '@ionic/vue';
import axios from "axios";
import { jsonDataURL, startCedatoPlayer } from '@uinterview/upolitics'

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonMenuButton,
		IonPage,
	},
	data() {
		return {
			componentKey: 0,
			article: [],
			ent: [],
			otherNews: [],
			otherNews2: [],
			jsonResp: [],
			commentList: ''
		};
	},
	methods:{
		startPlayer(article){
			if(this.article.content.includes('_cedato_') == true) {
				this.$refs.runVideo.innerHTML = ""
				this.$refs.startVideo.classList.add("hidden")
				const videoDiv = document.querySelector('[id^="cdt_"]').id;
				startCedatoPlayer(videoDiv)
			}
		},
		shareArticle(article){
			const shareOptions = {
				message: article.title,
				subject: article.title,
				files: [article.image_url], // an array of filenames either locally or remotely
				url: article.item_link,
				chooserTitle: 'Share With', // Android only, you can override the default share sheet title
				iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
			};
			const shareOnSuccess = function(result) {
				console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
				console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
			};
			const shareOnError = function(msg) {
				console.log("Sharing failed with message: " + msg);
			};
			window['plugins'].socialsharing.shareWithOptions(shareOptions, shareOnSuccess, shareOnError);
		},
		getComments(){
			this.$refs.commentsButton.style.display = 'none'
			this.$refs.blogCommentForm.style.display = 'block'
			this.$refs.commentsContainer.innerHTML = '<span class="loader"><img src="/assets/images/reload-circle-outline.svg">Loading comments...</span>'
			axios.get(`https://upolitics.com/messenger/get_comments.php?id=${this.$refs.commentsButton.getAttribute("postid")}&time=${new Date().getTime()}`)
				.then(response => {
					this.commentList = response.data
				})
		},
		putComment(){
			this.$refs.formMsg.innerHTML = ""
			this.$refs.formSuccessMsg.innerHTML = ""
			const commentAuthor = this.$refs.commentAuthor.value
			const commentAuthorEmail = this.$refs.commentAuthorEmail.value
			const commentText = this.$refs.commentText.value
			let continue1 = false
			let continue2 = false
			let continue3 = false
			if(commentAuthor == '') {
				this.$refs.commentAuthorMsg.innerHTML = "Please input your name"
			} else {
				this.$refs.commentAuthorMsg.innerHTML = ""
				continue1 = true
			}
			const re = /\S+@\S+\.\S+/;
			const emailValidForm = re.test(this.$refs.commentAuthorEmail.value)
			if( (commentAuthorEmail == '') || (emailValidForm == false) ) {
				this.$refs.commentAuthorEmailMsg.innerHTML = "Please input your email"
			} else {
				this.$refs.commentAuthorEmailMsg.innerHTML = ""
				continue2 = true
			}
			if(commentText == '') {
				this.$refs.commentTextMsg.innerHTML = "Please input your message"
			} else {
				this.$refs.commentTextMsg.innerHTML = ""
				continue3 = true
			}
			if( (continue1 == true) && (continue2 == true) && (continue3 == true) ) {
				const bodyFormData = new FormData();
				bodyFormData.append('comment_post_ID', this.$refs.commentsButton.getAttribute("postid"));
				bodyFormData.append('author', commentAuthor);
				bodyFormData.append('email', commentAuthorEmail);
				bodyFormData.append('comment', commentText);
				bodyFormData.append('url', '');
				this.$refs.submitBtn.value = "Loading..."
				axios.post('https://upolitics.com/messenger/put_comment.php', bodyFormData)
					.then((response) => {
						// const commentHTML = '<div id="comment-810" class="single-comment"><div class="row"><div class="comment-meta comment-author vcard col-lg-2 col-md-2 col-sm-3 col-xs-12"><img alt="Andrei Stefan Zahariade" src="https://secure.gravatar.com/avatar/169e60f5703ba8df800e88e02d27286a?s=200&amp;d=mm&amp;r=g" class="avatar avatar-200 photo" height="200" width="200" loading="lazy"></div><div class="col-lg-10 col-md-10 col-sm-9 col-xs-12"><div class="comment-content"><div class="comment-info"><cite><b class="fn">Andrei Stefan Zahariade</b> </cite> on <time datetime="2021-02-15T10:36:20+00:00">February 15, 2021 am28 10:36 am</time></div><p>test</p></div></div></div></div>'
						const commentHTML = response.data
						const newComment = document.createElement("LI")
						newComment.innerHTML = commentHTML
						document.getElementsByClassName('comment-list')[0].appendChild(newComment)
						this.$refs.submitBtn.value = "Send"
						this.$refs.commentAuthor.value = ""
						this.$refs.commentAuthorEmail.value = ""
						this.$refs.commentText.value = ""
						this.$refs.formSuccessMsg.innerHTML = "Comment added successfully!"
					})
					.catch(err => {
						this.$refs.formMsg.innerHTML = err.response.data
						this.$refs.submitBtn.value = "Send"
					});
			}
		}
	},
	mounted() {
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		this.$refs.startVideo.classList.remove("hidden")
		const cedatoScript = document.createElement('script')
		cedatoScript.setAttribute('src', 'https://c.cedatoplayer.com/player/ad-units.2.1.js')
		document.head.appendChild(cedatoScript)
		if (localStorage.appData) {
			const dateNow = Date.now()
			const jsonExpire = (JSON.parse(localStorage.appData).expires)*1000
			if(dateNow <= jsonExpire) {
				this.entriesList = JSON.parse(localStorage.appData)
			} else {
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
					})
			}
			this.article = this.entriesList.ubio[this.$route.params.id]
			this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
			this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
			if(this.article.content.includes('_cedato_') == true) {
				this.$refs.startVideo.classList.remove("hidden")
			} else {
				this.$refs.startVideo.classList.add("hidden")
			}
		} else {
			axios.get(jsonDataURL)
				.then(response => {
					this.entriesList = response.data
					localStorage.setItem('appData', JSON.stringify(response.data));
					this.article = this.entriesList.ubio[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
				})
		}
	},
	watch: {
		'$route' (to, from) {
			if(to.path == '/') {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							setTimeout(() => this.$refs.homeSlider.init(), 100);
								})
				}
			} else if(to.path.includes('/category/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
					this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
					this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.categCarousel = Object.values(this.entriesList[this.$route.params.id]).slice(0,3)
							this.otherNews = Object.values(this.entriesList[this.$route.params.id]).slice(1,5)
							this.newsList = Object.values(this.entriesList[this.$route.params.id]).slice(6)
						})
				}
			} else if(to.path.includes('/page/')){
				// do nothing
			} else if(to.path.includes('/videos/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.videos[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.videos[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.videos).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.videos).slice(6,18)
								})
				}
			} else if(to.path.includes('/polls/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.polls[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.polls[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.polls).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.polls).slice(6,18)
						})
				}
			} else if(to.path.includes('/coronavirus/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.coronavirus[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.coronavirus).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.coronavirus).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.coronavirus[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.coronavirus).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.coronavirus).slice(6,18)
						})
				}
			} else if(to.path.includes('/ubio/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.ubio[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.ubio[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.ubio).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.ubio).slice(6,18)
								})
				}
			} else if(to.path.includes('/opinion/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.opinion[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.opinion).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.opinion).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.opinion[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.opinion).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.opinion).slice(6,18)
								})
				}
			} else if(to.path.includes('/congress/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.congress[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.congress).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.congress).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.congress[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.congress).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.congress).slice(6,18)
								})
				}
			} else {
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.news[this.$route.params.id]
					this.otherNews = Object.values(this.entriesList.news).slice(0,6)
					this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.news[this.$route.params.id]
							this.otherNews = Object.values(this.entriesList.news).slice(0,6)
							this.otherNews2 = Object.values(this.entriesList.news).slice(6,18)
								})
				}
			}
			this.$el.querySelector("#container").scrollIntoView({behavior: 'smooth'});
			this.$refs.commentsButton.style.display = 'block'
			this.$refs.blogCommentForm.style.display = 'none'
			this.$refs.commentsContainer.innerHTML = ''
		}
	},
};
